import React from 'react';
import { graphql } from 'gatsby';
import Layout from 'components/shared/layout';
import Hat from 'components/shared/hat';
import Breadcrumb from 'components/shared/breadcrumb';
import Post from 'components/shared/post';

const NewsPost = ({ pageContext, data }) => {
  const {
    breadcrumb: { crumbs },
    crumbLabel,
    crumbPath,
  } = pageContext;

  const { markdownRemark } = data;

  const images = markdownRemark.frontmatter.gallery?.map(image => ({
    img: image.src.childImageSharp.fluid,
    title: image.title,
  }));

  const currentCrumb = crumbs.find(crumb => crumb.pathname === crumbPath);
  currentCrumb.crumbLabel = crumbLabel;

  return (
    <Layout>
      <Hat pageName={markdownRemark.frontmatter.title} />
      <Breadcrumb crumbs={crumbs} />
      <Post
        htmlAst={markdownRemark.htmlAst}
        galleryImages={images}
        title={markdownRemark.frontmatter.title}
      />
    </Layout>
  );
};

export default NewsPost;

export const pageQuery = graphql`
  query NewsPostById($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      htmlAst
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        gallery {
          src {
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
                presentationWidth
              }
            }
          }
          title
        }
      }
    }
  }
`;
